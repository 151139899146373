/**
 * Fetch data from the backend API, or from the cache if available.
 *
 * @param {String} endpoint The API endpoint
 * @param {*} opts The options
 *
 * @returns {*} The useFetch method with the API base URL preconfigured
 */
export function useWebsiteFetch(endpoint, opts = { refreshCache: false }) {
	const config = useRuntimeConfig();

	if (opts.key && !opts.refreshCache) {
		const cachedData = useNuxtData(opts.key);

		if (cachedData.data.value) {
			return cachedData;
		}
	}

	return useFetch(endpoint, {
		baseURL: config.public.apiBaseURL,
		...opts,
	});
}
